.wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 75px;
  font-family: "papyrus";
  background-color: #201f1c;
  border-bottom: 2px solid #f0ede4;
  position: fixed;
  z-index: 100;
}
.logo {
  width: 65px;
  height: auto;
  padding-left: 1.4rem;
  margin-bottom: 0.7rem;
  cursor: pointer;
  z-index: 20;
}
.nav {
  display: none;
  cursor: pointer;
}
.links {
  margin-left: -2rem;
}
.links > a > img {
  width: 30px;
  height: 30px;
  padding-right: 1.5rem;
  margin-bottom: 0.4rem;
}

.burgerMenu {
  margin-right: 1.4rem;
  cursor: "pointer";
  color: white;
  margin-bottom: 0.7rem;
}

@media only screen and (min-width: 750px) {
  .wrap {
    align-items: center;
    height: 85px;
  }

  .nav {
    display: flex;
    padding-top: 0.8rem;
  }

  .btn {
    display: flex;
    text-decoration: none;
    color: white;
    margin-right: 1.4rem;
  }

  .btn:hover {
    color: #e55d6f;
  }

  .btn > p {
    font-size: 1.2rem;
  }
  .active {
    border-bottom: 2px solid #e55d6f;
  }

  .logo {
    width: 60px;
    height: auto;
    /* margin-left: 1.4rem; */
    margin-bottom: -0.7rem;
  }
  .links {
    margin-left: 0rem;
    padding-right: 1rem;
  }

  .links > a > img {
    width: 25px;
    height: 25px;
    padding-right: 1rem;
    padding-top: 1rem;
    /* margin-left: -1.2rem; */
    margin-bottom: 0rem;
  }
  .burgerMenu {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  .btn {
    margin-right: 2.5rem;
  }

  .btn > p {
    font-size: 1.4rem;
  }

  .logo {
    width: 70px;
  }

  .links > a > img {
    width: 27px;
    height: 27px;
  }
}

@media only screen and (min-width: 1200px) {
  .btn {
    margin-right: 3rem;
  }
  .btn > p {
    font-size: 1.5rem;
  }

  .logo {
    width: 70px;
    margin-left: 3rem;
  }

  .links > a > img {
    width: 27px;
    height: 27px;
    margin-right: 1.5rem;
  }
}

@media only screen and (min-width: 1500px) {
  .logo {
    margin-left: 3rem;
  }

  .links > a > img {
    width: 30px;
    height: 30px;
    margin-right: 1.7rem;
  }
}

@media only screen and (min-width: 1650px) {
  .logo {
    margin-left: 9rem;
  }

  .btn {
    margin-right: 3.5rem;
  }

  .links {
    margin-right: 5rem;
  }
}
