.about {
  background-color: #201f1c;
  padding-top: 4rem;
  color: white;
}

.about__wrap > h2 {
  display: flex;
  font-family: "papyrus";
  font-size: 2rem;
  font-weight: 200;
  margin-bottom: 3rem;
  text-align: center;
  background-color: #45432a;
  width: 100%;
  height: 150px;
  align-items: center;
  justify-content: center;
}

.about__info {
  columns: 25em;
  width: 80%;
  padding: 3rem;
  margin: 0rem auto;
  text-align: center;
}
.about__info > p {
  line-height: 3.5rem;
  font-size: 1.2rem;
  font-family: "papyrus";
  word-spacing: 0.52rem;
}

.about__strong {
  color: #6b6838;
  font-size: 150%;
}

@media only screen and (min-width: 1020px) {
  .about__wrap > h2 {
    font-family: "papyrus";
  }
  .about {
    padding-top: 5rem;
  }

  .about__info > p {
    padding-right: 4rem;
    padding-left: 4rem;
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 1520px) {
  .about__info {
    columns: 32em;
    width: 80%;
  }
  .about__wrap > h2 {
    font-size: 2.2rem;
  }
}

@media only screen and (min-width: 1660px) {
  .about__info {
    columns: 32em;
    width: 70%;
  }
}

@media only screen and (min-width: 1820px) {
  .about__info {
    columns: 35em;
    width: 65%;
  }
}
