.cameras__wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem auto 2rem;
}

.cameras__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}

.cameras__left {
  width: 55%;
  margin: 0 auto 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.premium__logo {
  width: 85%;
  height: auto;
  margin-bottom: 2.5rem;
}

.premium__view {
  width: 100%;
  height: auto;
}

.cameras__right {
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
  margin: 2rem auto;
}

.cameras__right > p {
  font-size: 1.1rem;
  /* font-weight: 200; */
  line-height: 2rem;
  text-align: center;
  font-family: "comic sans ms";
}

.cameras__buttons {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-evenly;
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-top: 3rem;
}

.cameras__project > a {
  text-decoration: none;
  color: white;
  font-family: "papyrus";
  font-size: 1.1rem;
}

.cameras__project {
  background-color: #918d46;
  padding: 0.8rem;
  border-radius: 0px;
  width: 25%;
  text-align: center;
  border-radius: 10px;
}

.cameras__code > a {
  text-decoration: none;
  color: white;
  font-family: "papyrus";
  font-size: 1.1rem;
}

.cameras__code {
  background-color: #918d46;
  padding: 0.8rem;
  border-radius: 0px;
  width: 25%;
  text-align: center;
  border-radius: 10px;
  /* font-size: 1.2rem; */
}
.cameras__tech {
  font-family: "papyrus";
  font-size: 1.3rem;
  font-weight: 200;
  line-height: 2rem;
  text-align: center;
  margin-top: 3rem;
}

.cameras__tech__img {
  width: 95%;
  height: auto;
  border-radius: 10px;
  margin-top: 0.8rem;
}

@media only screen and (min-width: 700px) {
  .cameras__wrap {
    width: 85%;
  }

  .cameras__left {
    margin: 0 auto 3rem;
  }

  .premium__logo {
    margin-bottom: 1.5rem;
    width: 80%;
    margin-left: 1rem;
  }

  .cameras__right {
    margin: 1rem auto;
  }

  .cameras__project {
    width: 20%;
  }

  .cameras__code {
    width: 20%;
  }

  .cameras__tech {
    margin-top: 4rem;
  }
}

@media only screen and (min-width: 900px) {
  .cameras__wrap {
    width: 100%;
    margin: 1rem auto 3rem;
  }

  .cameras__main {
    flex-direction: row;
  }

  .cameras__left {
    width: 35%;
  }

  .premium__logo {
    width: 70%;
    margin-bottom: 2rem;
  }

  .premium__view {
    width: 90%;
  }

  .cameras__right {
    width: 40%;
  }

  .cameras__right > h2 {
    font-size: 1.1rem;
    font-weight: 400;
  }

  .cameras__buttons {
    font-size: 0.9rem;
    margin-top: 3rem;
  }

  .cameras__project {
    width: 30%;
  }

  .cameras__code {
    width: 30%;
  }

  .cameras__tech {
    font-size: 1.2rem;
  }

  .cameras__tech__img {
    width: 95%;
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 1100px) {
  .cameras__wrap {
    width: 90%;
  }

  .cameras__left {
    width: 40%;
  }

  .premium__logo {
    width: 60%;
  }

  .premium__view {
    width: 80%;
  }

  .cameras__right {
    width: 40%;
  }

  .cameras__right > h2 {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .cameras__buttons {
    font-size: 1rem;
    margin-top: 2rem;
  }
  .cameras__tech {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 1400px) {
  .cameras__wrap {
    width: 80%;
  }

  .premium__view {
    width: 75%;
  }
  .cameras__buttons {
    font-size: 1.1rem;
    margin-top: 3rem;
  }
  .cameras__project {
    width: 25%;
  }

  .cameras__code {
    width: 25%;
  }

  .cameras__project > a {
    font-size: 1.2rem;
  }

  .cameras__code > a {
    font-size: 1.2rem;
  }
  .cameras__tech__img {
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 1700px) {
  .cameras__wrap {
    width: 70%;
  }

  .cameras__main {
    margin-bottom: 7rem;
  }

  .cameras__left {
    width: 70%;
    margin-left: 0rem;
    margin-right: 5rem;
  }

  .premium__logo {
    width: 50%;
    margin-bottom: 3rem;
  }

  .premium__view {
    width: 80%;
  }

  .cameras__right {
    width: 80%;
  }

  .cameras__project {
    width: 20%;
  }

  .cameras__code {
    width: 20%;
  }

  .cameras__right > h2 {
    font-size: 1.3rem;
  }
  .cameras__tech__img {
    width: 80%;
  }
}
