.projects__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #201f1c;
  height: auto;
  color: white;
}

.project__header {
  display: flex;
  font-family: "papyrus";
  padding-top: 1rem;
  font-size: 2rem;
  font-weight: 200;
  text-align: center;
  margin-bottom: 2.5rem;
  background-color: #45432a;
  width: 100%;
  height: 125px;
  align-items: center;
  justify-content: center;
}

.project__info {
  text-align: center;
  margin: 1rem 0 2.5rem;
  font-family: "papyrus";
  font-size: 1.8rem;
  font-weight: 200;
}

@media only screen and (min-width: 100px) {
  .project__header {
    font-size: 2.3rem;
  }
}
