.home__wrap {
  display: flex;
  background-color: #201f1c;
  height: 100vh;
  font-family: "papyrus";
  color: #f0ede4;
}

.inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 11rem;
}
.home__logo {
  margin-left: 0rem;
  margin-bottom: 6rem;
  width: 40%;
}
.home__main {
  text-align: center;
}
.home__main > h2 {
  font-size: 2.3rem;
  font-weight: 200;
  margin-bottom: 1.5rem;
  color: #918d46;
}

.home__main > p {
  font-size: 1rem;
}

.buttons {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 2rem;
  height: 20px;
}
.projects {
  width: 30%;
  height: 20px;
  margin: 0 auto;
  background-color: #918d46;
  padding: 0.9rem;
  border-radius: 10px;
  color: white;
  margin-right: 5rem;
  text-align: center;
  cursor: pointer;
}

.buttons :hover {
  color: #e55d6f;
}

.resume {
  width: 30%;
  height: 20px;
  margin: 0 auto;
  background-color: #918d46;
  padding: 0.9rem;
  border-radius: 10px;
  color: white;
  text-align: center;
}

.resume > a {
  text-decoration: none;
  color: white;
}

@media only screen and (min-width: 400px) {
  .home__main > h2 {
    font-size: 3rem;
  }

  .home__main > p {
    font-size: 1.2rem;
  }

  .buttons {
    font-size: 1rem;
  }

  .projects {
    width: 25%;
    height: 20px;
  }

  .resume {
    width: 25%;
    height: 20px;
  }
}

@media only screen and (min-width: 900px) {
  .inner-wrap {
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 0rem;
  }
  .home__logo {
    width: 30%;
    height: auto;
    margin-bottom: 0rem;
  }

  .home__main {
    margin-left: 0rem;
  }

  .home__main > h2 {
    font-size: 3rem;
  }

  .home__main > p {
    font-size: 1.5rem;
  }

  .buttons {
    width: 100%;
    margin-top: 1.5rem;
    font-size: 1.3rem;
  }

  .projects {
    width: 26%;
  }

  .resume {
    width: 25%;
  }
}

@media only screen and (min-width: 1300px) {
  .home__logo {
    width: 27%;
  }
  .home__main > h2 {
    font-size: 3.3rem;
  }

  .home__main > p {
    font-size: 1.5rem;
  }

  .buttons {
    margin-top: 2rem;
  }
  .projects {
    width: 26%;
  }

  .resume {
    width: 25%;
  }
}

@media only screen and (min-width: 1500px) {
  .home__logo {
    width: 25%;
  }
  .home__main > h2 {
    font-size: 3.5rem;
  }

  .home__main > p {
    font-size: 1.6rem;
  }

  .buttons {
    margin-top: 3rem;
  }
  .projects {
    width: 30%;
  }

  .resume {
    width: 30%;
  }
}

@media only screen and (min-width: 1800px) {
  .home__logo {
    width: 25%;
    margin-left: 5rem;
  }
  .home__main {
    margin-left: 1rem;
  }
}
