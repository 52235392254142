.contact {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 2rem;
  text-align: center;
  background-color: #201f1c;
  height: 70vh;
}

.contact__header {
  height: 150px;
  background-color: #45432a;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__header > h2 {
  font-family: "papyrus";
  font-size: 2rem;
  font-weight: 200;
  margin-bottom: 1.5rem;
  color: #f0ede4;
  margin-top: 2rem;
}

.contact__info {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 4rem;
}
.contact__info__header {
  line-height: 3.5rem;
  font-size: 1.2rem;
  font-family: "papyrus";
  color: white;
  letter-spacing: 0.12rem;
}

.contact__info__info {
  line-height: 3.5rem;
  font-size: 1.2rem;
  font-family: "papyrus";
  color: white;
  letter-spacing: 0.12rem;
  margin-bottom: 3rem;
}

.geometry {
  position: absolute;
  right: 0;
  z-index: 10;
  margin-top: -3rem;
  width: 400px;
}

@media only screen and (min-width: 700px) {
  .contact__info {
    margin-top: 5rem;
  }
}

@media only screen and (min-width: 1000px) {
  .contact {
    padding-top: 8rem;
    height: 80vh;
  }
  .contact__header {
    height: 150px;
  }

  .contact__header > h2 {
    font-size: 2rem;
  }

  .contact__info {
    width: 100%;
    justify-content: space-evenly;
    margin-top: 4rem;
  }
  .geometry {
    margin-top: -15rem;
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) {
  .contact__info {
    width: 100%;
    margin-top: 5rem;
  }
  .contact__header > h2 {
    font-size: 2.3rem;
  }

  .contact__email {
    padding-top: 1rem;
  }
}

@media only screen and (min-width: 1500px) {
  .contact__info {
    width: 100%;
  }
  .geometry {
    margin-top: -25rem;
    width: 480px;
  }
}
